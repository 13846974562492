import React from "react";
import { Container, Row, Col } from "reactstrap";
import headshot from "./headshot.jpg";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./App.css";
import MyNavBar from "./components/MyNavBar";
import Timeline from "./components/Timeline";

library.add(fab, faCheckSquare, faLaptopCode);

function App() {
  return (
    <div className="App">
      <MyNavBar />
      <header className="App-header">
        <Container>
          <Row>
            <Col>
              <div className="profile-pic">
                <img src={headshot} className="headshot" alt="kara mansel" />
              </div>
              <h3 className="title">Software Developer</h3>
              <div className="social-links">
                <a
                  className="socials"
                  href="https://twitter.com/klaniece?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                </a>
                <a
                  className="socials"
                  href="https://github.com/klmansel"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", "github"]} />
                </a>
                <a
                  className="socials"
                  href="https://www.linkedin.com/in/karamansel/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", "linkedin"]} />
                </a>
              </div>
            </Col>
            <Col>
              <div className="mid-section">
                <p className="about-me">
                  Hi, I'm Kara and I love to <code>code</code>! I started my
                  career in sales, marketing, and technical support, but
                  eventually discovered programming and never looked back!
                </p>
                <p className="about-me">
                  I'm a primarily a Ruby developer, but I play around with other
                  languages and love trying new things! I've also worked with
                  React and other JavaScript frameworks and libraries, Apex,
                  Visualforce, and C++.
                </p>
                <p className="about-me">
                  I enjoy staying connected to the tech community and helping
                  others learn and grow in their tech careers. I am a Director
                  with
                  <a
                    href="https://www.womenwhocode.com/greenville"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {" "}
                    Women Who Code Greenville
                  </a>
                  , the local chapter of a global nonprofit with a mission to
                  support women in tech careers. As a leader with Women Who
                  Code, I have written and led several workshops, meetups, and
                  study sessions. I also designed and led a summer camp for
                  middle school students learning to code in Summer 2019.
                </p>
                <p className="about-me">
                  When I'm not writing code or talking tech, I am traveling -
                  probably somewhere sunny!
                </p>
                <p className="about-me">
                  Feel free to connect with me on LinkedIn or follow me on
                  Twitter!
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Timeline />
          </Row>
        </Container>
      </header>
    </div>
  );
}

export default App;
