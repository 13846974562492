import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "../styles/Timeline.css";

class Timeline extends Component {
  render() {
    return (
      <VerticalTimeline className="timeline">
        <VerticalTimelineElement
          className="timeline-element vertical-timeline-element--work"
          date="September 2019-Present"
          iconStyle={{ background: "#4c8492", color: "#4c8492" }}
        >
          <h3 className="vertical-timeline-element-title">Grace Hill</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Software Engineer
          </h4>
          <p>
            I am currently a software engineer with Grace Hill. We build
            elearning software for the property management industry. I work with
            Ruby on Rails, React, Redux and helped start the company's move to
            automated testing from fully manual testing.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="timeline-element vertical-timeline-element--work"
          date="August 2018-September 2019"
          iconStyle={{ background: "#f7e0a3", color: "#4c8492" }}
        >
          <h3 className="vertical-timeline-element-title">Division of Labor</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Software Engineer
          </h4>
          <p>
            As a software engineer with Division of Labor. I worked on several
            Rails projects as a consultant including being a core member of the
            engineering team for OffSite - a digital marketing startup. I've
            also consulted on two large Salesforce projects as the sole
            developer implementing custom solutions using Apex, Visualforce, and
            declarative configuration.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="timeline-element vertical-timeline-element--work"
          date="November 2017"
          iconStyle={{ background: "#4c8492", color: "#4c8492" }}
        >
          <h3 className="vertical-timeline-element-title">Fusion Web Clinic</h3>
          <h4 className="vertical-timeline-element-subtitle">Test Engineer</h4>
          <p>
            I got my first software job as a Test Engineer with an EMR software
            company in Greenville, SC where I conducted manual testing, wrote
            documentation, and helped create an automated testing suite for a
            custom JavaScript framework.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="timeline-element vertical-timeline-element--work"
          date="January 2017"
          iconStyle={{ background: "#f7e0a3", color: "#4c8492" }}
        >
          <h3 className="vertical-timeline-element-title">
            Started Oregon State University
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            {" "}
            Postbaccalaureate - BS Computer Science{" "}
          </h4>
          <p>
            Started the OSU Post Bacc BS in CS program online to deepen my
            knowledge of computers, networking, data science, and software.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="timeline-element vertical-timeline-element--work"
          date="August 2016"
          iconStyle={{ background: "#4c8492", color: "#4c8492" }}
        >
          <h3 className="vertical-timeline-element-title">
            Completed The Iron Yard
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Front End Engineering Certificate
          </h4>
          <p>
            Completed a 12 week immersive study programming bootcamp. I studied
            coding fundamentals primarily using JavaScript.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="timeline-element vertical-timeline-element--work"
          date="Nov 2014 - December 2017"
          iconStyle={{ background: "#f7e0a3", color: "#4c8492" }}
        >
          <h3 className="vertical-timeline-element-title">Apple</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Tier II Tech Support and Chat Advisor
          </h4>
          <p>
            I worked with customers on phone, email, and chat to resolve new and
            emerging software and hardware issues with Apple devices including
            iPhones, iMacs, MacBooks, and AirPods.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="timeline-element vertical-timeline-element--work"
          date="Jan 2014 - Aug 2015"
          iconStyle={{ background: "#4c8492", color: "#4c8492" }}
        >
          <h3 className="vertical-timeline-element-title">Malia Laine</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Social Media Manager
          </h4>
          <p>
            I created digital ad campaigns using Google Ads and several social
            media networks. I also managed all customer support and engagement
            for the brand.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="timeline-element vertical-timeline-element--work"
          date="July 2008 - March 2014"
          iconStyle={{ background: "#f7e0a3", color: "#4c8492" }}
        >
          <h3 className="vertical-timeline-element-title">Verizon</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Sales and Tech Support
          </h4>
          <p>
            I worked in sales in a store ranked top 10 in the US. I supported
            new product launches, hardware and software support in person and in
            a call center environment.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    );
  }
}

export default Timeline;
